export enum DocumentationTypeEnum {
    Document,
    Url,
    HtmlContent
}

export interface DocumentModel {
    documentationId: number;
    nom: string;
    description: string;
    fileName: string;

    isDownloading: boolean;
    type: DocumentationTypeEnum;
}

