
import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { of as observableOf, Subscription } from 'rxjs';
import { concatMap, delay, finalize, first } from 'rxjs/operators';
import { RestaurantTypeEnum } from '../shared/enums/restaurant-type.enum';
import { FileHelper } from '../shared/helpers/file.helper';
import { NotificationModel } from '../shared/models/notification.model';
import { LogoRouteService } from '../shared/services/api/logo/logo-route.service';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { InteractionService } from '../shared/services/interaction.service';
import { DocumentationTypeEnum, DocumentModel } from './models/documentheque.model';
import { DocumenthequeService } from './services/documentheque.service';

@Component({
  selector: 'app-documentheque',
  templateUrl: './documentheque.component.html',
  styleUrls: ['./documentheque.component.scss']
})
export class DocumenthequeComponent implements OnInit {
  private contratSubscription: Subscription;
  private restaurantSubscription: Subscription;

  private _cuisineCentraleId: number;
  private _contratId: number;
  public logoContratUrl: string = '';

  public searchText: string;
  private searchWords: string[];
  public documents: DocumentModel[];
  public displayedDocuments: DocumentModel[];

  /** Pagination */
  private _page: number;

  get page(): number {
    return this._page;
  }
  set page(value: number) {
    if (value > 0 && value <= this.totalPages) {
      this._page = value;
    }
  }

  private documentsPerPage: number = 10;
  public pages: number[];
  public totalPages: number;

  public currentType: RestaurantTypeEnum;
  public isLoadingDocuments: boolean;

  constructor(
    private router: Router,
    private appDataService: ApplicationDataService,
    private documenthequeService: DocumenthequeService,
    private logoService: LogoRouteService,
    private interactionService: InteractionService,
    private sanitizer: Sanitizer,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.contratSubscription = this.appDataService.contrat$.subscribe(contrat => {
      if (contrat) {
        this._cuisineCentraleId = contrat.cuisineCentraleId;
        this._contratId = contrat.id;
        this.logoContratUrl = this.logoService.GetPicture2Url(contrat.id, contrat.cuisineCentraleId);
        this.getDocuments();
      }
    });

    this.restaurantSubscription = this.appDataService.restaurant$.subscribe(rest => {
      if (rest != null) {
        this.currentType = rest.type;
      }
    });

    this.appDataService.changePageTitle('Documenthèque');

    this._page = 1;
  }

  ngOnDestroy() {
    this.contratSubscription.unsubscribe();
    this.restaurantSubscription.unsubscribe();
  }

  public searchDocuments(e: any) {
    e.preventDefault();
    if (this.searchText != null && this.searchText != '') {
      this.searchWords = this.searchText.split(' ');
    } else {
      this.searchWords = null;
    }

    this.getDocuments();
  }

  getDocuments() {
    this.isLoadingDocuments = true;
    this.documenthequeService
      .getDocuments(this._cuisineCentraleId, this._contratId, this.searchWords).pipe(
        // On attend au minimum 1 seconde pour éviter le clignotement
        concatMap(
          () => {
            return observableOf(true).pipe(delay(1000));
          },
          a => {
            return a;
          }
        ),
        first(),
        finalize(() => {
          this.isLoadingDocuments = false;
        }))
      .subscribe(
        documents => {
          this.handleNewDocuments(documents);
        },
        err => {
          this.interactionService.showSimpleMessage('Erreur', 'Une erreur est survenue lors de la récupération de la liste des documents.');
          this.handleNewDocuments([]);
        }
      );
  }

  private handleNewDocuments(docs: DocumentModel[]) {
    this.documents = docs;
    this.totalPages = Math.ceil(this.documents.length / this.documentsPerPage);
    this.pages = new Array<number>();
    for (let i = 2; i <= this.totalPages - 1; i++) {
      this.pages.push(i);
    }

    this.refreshDisplayedDocuments();
  }

  public getDocument(document: DocumentModel) {
    if (document.type == DocumentationTypeEnum.Url) {
      // HACK dans le cas des lien web, on utilise directement le filename ou lien de charger le content car le traitement asynchrone du chargement
      // fait basculer l'ouverture de la page en type popup via script (avec risque de blocage) plutot qu'en type ouverture d'un lien (pas de risue de blocage)

      window.open(document.fileName, '_blank', 'location=yes');
      return;
    }

    if (document.isDownloading) {
      return;
    }

    document.isDownloading = true;
    this.documenthequeService
      .getDocument(document.documentationId).pipe(
        first(),
        finalize(() => {
          document.isDownloading = false;
        }))
      .subscribe(
        fileresult => {
          if (document.type == DocumentationTypeEnum.HtmlContent) {
            // lecture du contenu pour injection dans une popup

            // extract blob Content
            const reader: FileReader = new FileReader();
            reader.onloadend = e => {
              // Html popup
              const notif = new NotificationModel();
              notif.message = this.domSanitizer.bypassSecurityTrustHtml(reader.result as string);
              this.interactionService.showNotification(notif);
            };
            reader.onerror = e => {
              this.interactionService.showSimpleMessage('Erreur', 'Une erreur est survenue lors de l\'extraction du document. : ' + JSON.stringify(reader.error));
            };
            reader.readAsText(fileresult.file, 'ISO-8859-1');
          } else {
            // document.type == DocumentationTypeEnum.Document
            // enregistrement du fichier
            FileHelper.saveFile(fileresult);
          }
        },
        err => {
          this.interactionService.showSimpleMessage('Erreur', 'Une erreur est survenue lors de l\'extraction du document.');
        }
      );
  }

  public prev() {
    this.page--;
    this.refreshDisplayedDocuments();
  }

  public next() {
    this.page++;
    this.refreshDisplayedDocuments();
  }

  public changePage(page: number) {
    this.page = page;
    this.refreshDisplayedDocuments();
  }

  public isPageDisplayed(page: number): boolean {
    if (this.page == 1) {
      return page <= this.page + 2 && page >= this.page - 1;
    } else if (this.page == this.totalPages) {
      return page <= this.page + 1 && page >= this.page - 2;
    } else {
      return page <= this.page + 1 && page >= this.page - 1;
    }
  }

  refreshDisplayedDocuments() {
    const start = (this.page - 1) * this.documentsPerPage;
    const end = this.page * this.documentsPerPage;

    if (this.documents) {
      this.displayedDocuments = this.documents.slice(start, end);
    }
  }
}
