import { LinkFormControlDirective } from '../../shared/directives/link-form-control.directive';
import { CommandeRepasService } from '../services/commande-repas.service';
import {
  FamillePlatModel,
  JourModel,
  ModeSaisieEnum,
  ModeSaisiePortionEnum,
  PeriodeContratTypeEnum,
  PortionModel,
  PrestationModel
} from '../models/commande-repas.model';
import { PrestationFormModel } from '../models/prestation-form.model';
import { MathHelper } from '../../shared/helpers/math.helper';
import { DateHelper } from '../../shared/helpers/date.helper';

import { Component, Input, Output, OnInit, EventEmitter, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';

import { PrestationValidator } from '../../shared/validators/prestation.validator';
import { NumberValidator } from '../../shared/validators/number.validator';

import { FormControlHelper } from '../../shared/helpers/form-control.helper';

import { ValidationResult } from '../../shared/models/validators/validation-result';
import { RestaurantTypeEnum } from '../../shared/enums/restaurant-type.enum';

const DEBOUNCE_INPUT_MS = 1000;
/** TODO : We should build the simple immediately here*/
@Component({
  selector: 'app-prestation',
  templateUrl: './prestation.component.html',
  styleUrls: ['./prestation.component.scss']
})
export class PrestationComponent implements OnInit {
  @ViewChild('prestaInput', { static: true }) prestaInputRef: ElementRef;
  @ViewChild('prestaRegimeInputRef', { static: false }) prestaRegimeInputRef: ElementRef;
  @ViewChildren(LinkFormControlDirective) linkFormControls: QueryList<LinkFormControlDirective>;

  /** Inputs / Output  */
  @Input()
  currentType: RestaurantTypeEnum;
  @Input()
  prestation: PrestationModel;
  @Input()
  jour: JourModel;
  @Input()
  isCurrent: boolean;

  @Output()
  prestaToggle: EventEmitter<{ id: number, shouldCollapse: boolean }> = new EventEmitter<{ id: number, shouldCollapse: boolean }>();

  @Output()
  onIsDirtyChange: EventEmitter<void> = new EventEmitter<void>();

  /** Fields */
  inputMaxValue: number = 9999;
  isModeStandard: boolean;
  hasRegime: boolean;
  /** Form  */

  private prestaForm: FormGroup;
  public simplePrestaFormGroup: PrestaFormGroup;

  /** getter setter */

  public get isDirty(): boolean {
    return this.prestaForm.dirty;
  }

  private get effectifValue(): number {
    return this.simplePrestaFormGroup.effectifQuantiteControl.value;
  }

  private set effectifValue(val: number) {
    this.simplePrestaFormGroup.effectifQuantiteControl.setValue(val);
  }

  public get effectifSansPorcQuantiteControlError(): string {
    const err =  this.simplePrestaFormGroup.effectifSansPorcQuantiteControl.errors as ValidationResult;
    return err
      ? err['range'].error
      : '';
  }

  public get effectifQuantiteControlError(): string {
    const err =  this.simplePrestaFormGroup.effectifQuantiteControl.errors as ValidationResult;
    return err
      ? err['range'].error
      : '';
  }

  /** Init */

  constructor(private builder: FormBuilder,
              private commandeRepasService: CommandeRepasService) {
  }

  ngOnInit() {
    this.isModeStandard = this.prestation.modeSaisie == ModeSaisieEnum.Standard;
    this.hasRegime = this.isModeStandard && this.prestation.regimeData !== undefined;

    // 1. On build les forms reactives
    this.buildForm();

    // 2. On créé un modèle plus facile à parcourir
    this.simplePrestaFormGroup = this.buildSimplePrestaFormModel();

    // 3. On met en place la validation
    this.applyValidation();
  }

  public tryFocusControl(fc: FormControl, hasChangedView: boolean, isRegime?: boolean) {
    if (this.linkFormControls) {
      const directive = this.linkFormControls.find(lfc => {
        return lfc.formControl == fc;
      });

      if (directive) {
        if (directive.nativeElement.disabled || this.isModeStandard) {
          if (this.hasRegime) {
            this.focusElement(this.prestaRegimeInputRef, hasChangedView);
          } else {
            this.focusElement(this.prestaInputRef, hasChangedView);
          }
        } else {
          this.focusElement(directive, hasChangedView);
        }
      }
    }
  }

  private focusElement(el: ElementRef, hasChangedView: boolean) {
    const action = () => {
      if (this.prestation.isCollapsed) {
        this.toggle();
      }

      el.nativeElement.focus();
    };

    if (hasChangedView) {
      setTimeout(() => {
        action();
      }, 500);
    } else {
      action();
    }
  }

  /** Logic */
  toggle() {
    this.prestaToggle.emit({
      id: this.prestation.prestationId,
      shouldCollapse: !this.prestation.isCollapsed
    });
  }

  handlePortionPercentageFocusOut(editedPortion: PortionFormGroup) {
    if (editedPortion.effectifPortionPourcentageControl.value == null) {
      editedPortion.effectifPortionPourcentageControl.setValue(0);
    }

    // round %, don't allow decimals
    editedPortion.effectifPortionPourcentageControl.setValue(Math.round(editedPortion.effectifPortionPourcentageControl.value));

    // new rounded %
    const newPercentage = editedPortion.effectifPortionPourcentageControl.value;
    this.calculateEffectifByPercentage(editedPortion);

    const famillePlatFormGroup = this.getFamillePlatFormGroup(editedPortion.famillePlatName);
    const portionsLength = famillePlatFormGroup.portionsControls.length;
    const diff = editedPortion.lastPourcentage - newPercentage;
    // Reventilation du différentiel sur les autres portions
    if (diff > 0) {
      // Ajout de portions sur la première "autre" portion > 0, à défaut la dernière
      let portionToAdd: PortionFormGroup = null;

      for (let i = 0; i < portionsLength; i++) {
        const portion = famillePlatFormGroup.portionsControls[i];

        if (portion.effectifPortionId != editedPortion.effectifPortionId) {
          if (!portionToAdd) {
            // on set par default la première différente au cas ou les critères ne match pas ensuite
            portionToAdd = portion;
            // Si la première valeur est différente de 0, on a déja trouvé la bonne
            if (portion.effectifPortionPourcentageControl.value != 0) {
              break;
            }
          } else if (portion.effectifPortionPourcentageControl.value != 0) {
            portionToAdd = portion;
          }
        }
      }

      FormControlHelper.addValue(portionToAdd.effectifPortionPourcentageControl, diff);
      // refresh lastPourcentage
      portionToAdd.lastPourcentage = portionToAdd.effectifPortionPourcentageControl.value;
      this.calculateEffectifByPercentage(portionToAdd);
    } else {
      // On soustrait le différentiel sur les autres portions, jusqu'à avoir un relicat de zéro
      let toSubstract = -diff;
      for (let i = portionsLength - 1; i >= 0; i--) {
        const portion = famillePlatFormGroup.portionsControls[i];

        if (portion.effectifPortionId != editedPortion.effectifPortionId) {
          if (portion.effectifPortionPourcentageControl.value != 0) {
            // Substract the maximum possible then returns the rest
            toSubstract = FormControlHelper.substractValue(portion.effectifPortionPourcentageControl, toSubstract);
            portion.lastPourcentage = portion.effectifPortionPourcentageControl.value;
            this.calculateEffectifByPercentage(portion);

            if (toSubstract == 0) {
              break;
            }
          }
        }
      }
    }

    this.adjustGap(famillePlatFormGroup);
    this.computeFamillePlatByName(famillePlatFormGroup.famillePlatName);

    editedPortion.lastPourcentage = newPercentage;
  }

  handlePrestaSansPorcFocusOut() {
    const effectifSansPorcCtrl = this.simplePrestaFormGroup.effectifSansPorcQuantiteControl;
    if (effectifSansPorcCtrl.value == null) {
      effectifSansPorcCtrl.setValue(this.simplePrestaFormGroup.effectifSansPorcQuantitePreco);
    }
  }

  handlePrestaFocusOut() {
    if (this.effectifValue == null) {
      this.effectifValue = this.prestation.quantitePrecommande;
    }

    if (this.isModeStandard) {
      return;
    }

    if (this.prestation.autoriserSaisieTauxPrise) {
      this.applyTauxPriseBrassage();
    } else {
      this.applyRule2And3OnAllFamillePlats();
    }
  }

  handlePortionFocusOut(portion: PortionFormGroup) {
    if (portion.effectifPortionQuantiteControl.value == null) {
      portion.effectifPortionQuantiteControl.setValue(portion.effectifPortionQuantitePreco);
    }

    if (this.isModeStandard) {
      return;
    }

    if (this.prestation.autoriserSaisieTauxPrise) {
      this.calculatePortionPercentage(portion);
    }

    // A ce niveau, on est sur la portion donc on update juste le total sa famille de plat
    // Si en taux de prise, va recalculer les %
    this.computeFamillePlatByName(portion.famillePlatName);

    // règle 11, on enregistre le dernier composant modifié
    this.commandeRepasService.lastFamillePlatByEffectifId[this.prestation.effectifId] = portion.famillePlatName;
  }

  computeFamillePlatByName(famillePlatName: string) {
    const famillePlatFormGroup = this.getFamillePlatFormGroup(famillePlatName);

    let total = 0;
    famillePlatFormGroup.portionsControls.forEach(portion => {
      total +=  portion.effectifPortionQuantiteControl.value as number;
    });

    famillePlatFormGroup.famillePlatQuantiteControl.setValue(total);
  }

  private computeFamillePlatControl(famillePlatControl: FamillePlatFormGroup) {
    let total = 0;
    famillePlatControl.portionsControls.forEach(pc => {
      total +=  pc.effectifPortionQuantiteControl.value as number;
    });

    famillePlatControl.famillePlatQuantiteControl.setValue(total);
  }

  /** Construction du ReactiveForm */

  private get isPrestaDisabled(): boolean {
    return this.jour.periode.type == PeriodeContratTypeEnum.HorsPeriode || this.jour.periode.ratioModification == 0;
  }

  private buildForm() {
    // On ajoute les effectif dans un dictionnaire pour gérer les erreurs sur la réponse du POST
    const quantiteJourControl = this.builder.control(this.prestation.quantiteJour);
    this.commandeRepasService.effectifControls[this.prestation.effectifId] = quantiteJourControl;

    this.prestaForm = this.builder.group(
      {
        effectifQuantiteControl: quantiteJourControl,
        famillePlatsControls: this.buildFamillePlats()
      });

    if (this.hasRegime) {
      const quantiteSansPorcJourControl = this.builder.control(this.prestation.regimeData.quantiteJour);
      this.prestaForm.addControl('effectifSansPorcQuantiteControl', quantiteSansPorcJourControl);
      this.commandeRepasService.effectifControls[this.prestation.regimeData.effectifId] = quantiteSansPorcJourControl;
    }
  }

  private buildFamillePlats(): FormArray {
    const famillePlatGroup = this.builder.array([]);
    const length = this.prestation.famillePlats.length;
    this.prestation.famillePlats.forEach((fp: FamillePlatModel, i: number) => {
      famillePlatGroup.push(this.buildFamillePlat(fp));
    });

    return famillePlatGroup;
  }

  private buildFamillePlat(fp: FamillePlatModel): FormGroup {
    let total = 0;
    fp.portions.map(p => total += p.quantite);

    const famillePlatQuantiteControl = this.builder.control(total);
    const portionArray = this.builder.array([]);
    const controlList = [];
    fp.portions.map(p => {
      const portion = this.buildPortion(p, fp, famillePlatQuantiteControl);
      controlList.push(portion.control);
      portionArray.push(portion.group);
    });

    this.commandeRepasService.addPortionToDictionary(controlList, famillePlatQuantiteControl, this.prestation.effectifId, fp.famillePlat);
    return this.builder.group({
      famillePlatName: fp.famillePlat,
      ordre: fp.famillePlatOrdre,
      autoriserEffectifZero: fp.autoriserEffectifZero,
      isComposanteRestreinte: fp.isComposanteRestreinte,
      quantiteInitialeFamille: total,
      famillePlatQuantiteControl,
      portionsControls: portionArray
    });
  }

  private buildPortion(p: PortionModel, fp: FamillePlatModel, famillePlatControl: FormControl): { group: FormGroup, control: FormControl } {
    const portionQuantiteControl = this.builder.control(p.quantite, [Validators.required]);
    this.commandeRepasService.effectifPortionControls[p.effectifPortionId] = portionQuantiteControl;

    return {
      group: this.builder.group({
        effectifPortionId: p.effectifPortionId,
        platName: p.plat,
        tooltip: p.portionTooltipInfo,
        modeSaisie: p.modeSaisie,
        // calculate in simpleform build
        effectifPortionPourcentageControl: 0,
        effectifPortionQuantiteControl: portionQuantiteControl,
        effectifPortionQuantitePreco: { value: p.quantitePrecommande, disabled: true }
      }),
      control: portionQuantiteControl
    };
  }

  /** Changes et Validations  */

  applyValidation() {
    // REGLE 16
    let min = this.prestation.quantiteJourMin;
    let max = this.prestation.quantiteJourMax;
    if (min == null) {
      min = 0;
    }
    if (max == null) {
      max = this.inputMaxValue;
    }

    FormControlHelper.addValidator(this.simplePrestaFormGroup.effectifQuantiteControl, [NumberValidator.range(min, max)]);

    if (this.hasRegime) {
      let regimeMin = this.prestation.regimeData.quantiteJourMin;
      let regimeMax = this.prestation.regimeData.quantiteJourMax;
      if (regimeMin == null) {
        regimeMin = 0;
      }
      if (regimeMax == null) {
        regimeMax = this.inputMaxValue;
      }

      FormControlHelper.addValidator(this.simplePrestaFormGroup.effectifSansPorcQuantiteControl, [NumberValidator.range(regimeMin, regimeMax)]);
    }

    this.simplePrestaFormGroup.famillePlatsControls
      .map(fp => {
        // REGLE 5 ET 6
        this.addValidatorsRule5and6(fp.famillePlatQuantiteControl);
      });
  }

  addValidatorsRule5and6(fp: FormControl) {
    FormControlHelper.addValidator(fp, [PrestationValidator.rule5(() => this.effectifValue), PrestationValidator.rule6(() => this.effectifValue)]);
  }

  public applyRule1() {
    if (this.prestation.autoriserSaisieTauxPrise) {
      return;
    }

    this.effectifValue = this.prestation.quantiteJour;

    if (this.hasRegime) {
      this.simplePrestaFormGroup.effectifSansPorcQuantiteControl.setValue(this.prestation.regimeData.quantiteJour);
      // Si saisie standard avec régime, on update pas les portions
      return;
    }

    this.simplePrestaFormGroup.famillePlatsControls.forEach(fpg => {
      let firstFound = false;
      fpg.portionsControls.forEach(portion => {
        if (portion.isDisabled) {
          return;
        }

        if (!firstFound) {
          firstFound = true;
          portion.effectifPortionQuantiteControl.setValue(this.prestation.quantiteJour);
        } else {
          portion.effectifPortionQuantiteControl.setValue(0);
        }

        this.handlePortionFocusOut(portion);
      });

      this.computeFamillePlatControl(fpg);
    });
  }

  /**
   * Applique les règles 2 ou 3 à toutes les familles spécifiés en ajustant le gap entre le total de la famille et le total de la prestation
   *
   * @private
   * @param {...FamillePlatFormGroup[]} famillePlatsControls - les familles de plats à mettre à jour, si null/vide on prends toutes les familles de la prestation
   * @memberof PrestationComponent
   */
  private applyRule2And3OnAllFamillePlats(...famillePlatsControls: FamillePlatFormGroup[]) {
    // si l'argument famillePlatsControls est null/vide on prends toutes les familles de la prestation par défaut
    famillePlatsControls = (famillePlatsControls && famillePlatsControls.length > 0)
      ? famillePlatsControls
      : this.simplePrestaFormGroup.famillePlatsControls;

    famillePlatsControls.map(fpg => {
      // on recalcule le total dans le cas où l'on viendrait de modifier une portion
      this.computeFamillePlatControl(fpg);
    });

    famillePlatsControls.map(fpg => {
      // on applique la différence
      const difference = this.effectifValue - fpg.famillePlatQuantiteControl.value;
      if (difference > 0) {
        if (this.checkCanIncreaseQuantiteOnFamille(fpg)) {
          this.applyRule2(fpg, difference);
        }
      } else {
        this.applyRule3(fpg, -difference);
      }
      // on doit update la famille de plat car on vient de modifier l'effectif
      this.computeFamillePlatControl(fpg);
    });
  }

  // Applique un incrément d'effectifs sur la famille de plats
  private applyRule2(fpg: FamillePlatFormGroup, toAdd: number) {
    const length = fpg.portionsControls.length;
    let portionToAdd: PortionFormGroup = null;
    for (let i = 0; i < length; i++) {
      const portionControl = fpg.portionsControls[i];
      if (!portionControl.isDisabled) {
        // set the first not disabled if we have no portionToAdd yet (default value)
        if (portionControl.effectifPortionQuantiteControl.value != undefined && !portionToAdd) {
          portionToAdd = portionControl;
          if (portionToAdd.effectifPortionQuantiteControl.value != 0) {
            break;
          }
        } else if (portionControl.effectifPortionQuantiteControl.value != 0) {
          // found the good one
          portionToAdd = portionControl;
          break;
        }
      }
    }

    if (portionToAdd) {
      FormControlHelper.addValue(portionToAdd.effectifPortionQuantiteControl, toAdd, this.inputMaxValue);
      if (this.prestation.autoriserSaisieTauxPrise) {
        this.calculatePortionPercentage(portionToAdd);
      }
    }
  }

  /**
   * Vérifie si l'on peut augmenter l'effectif d'une famille:
   * On peut modifier une famille initialement à zéro si :
   * - on est en precommande OU sur une prestation dont la saisie à zéro a été autorisée (LimitationContratPrestation)
   *      => calculé implicitement sur le serveur (champs QuantiteMin/Max de la prestation + ModeSaisie sur les portions)
   * - ET la famille n'est pas une composante restreinte OU que la limitation n'est pas déjà atteinte
   * - ET que la famille n'était pas initialement à zéro (cas 2bis #34319) OU que pour cette prestation une seule famille avec un seul choix (portion) soit possible
   */
  private checkCanIncreaseQuantiteOnFamille(fpg: FamillePlatFormGroup): boolean {
    let canUpdate = true;
    // - on est en precommande OU sur une prestation dont la saisie à zéro a été autorisée (LimitationContratPrestation)
    // => on vérifie juste que la prestation peut être modifiée, les vérification sous-jacentes sont faites côtés serveur
    canUpdate = this.prestation.quantiteJourMax == null
      || (this.prestation.quantiteJourMax > 0
        && this.prestation.quantiteJour < this.prestation.quantiteJourMax);
    // - ET la famille n'est pas une composante restreinte ou que la limitation n'est pas déjà atteinte
    canUpdate = canUpdate
      && this.checkFamilleLimitationComposanteIsValidToIncreaseQuantite(fpg);
    // - ET que la famille n'était pas initialement à zéro (cas 2bis #34319) OU que pour cette prestation une seule famille avec un seul choix (portion) soit possible
    canUpdate = canUpdate
      && ((fpg.famillePlatQuantiteControl.value > 0 || fpg.quantiteInitialeFamille > 0) // la famille n'était pas initialement à zéro (cas 2bis #34319)
        || (this.prestation.famillePlats.length == 1 // Une seule famille pour la prestation
          && fpg.portionsControls.filter(pfg => !pfg.isDisabled).length == 1) // Une seule portion sur la famille
      );

    return canUpdate;
  }

  /**
   * vérifie si la famille est une composante restreinte et que la limitation n'est déjà atteinte
   */
  private checkFamilleLimitationComposanteIsValidToIncreaseQuantite(fpg: FamillePlatFormGroup): boolean {
    if (!fpg.isComposanteRestreinte // famille non restreinte => on peut saisir un effectif
      || fpg.famillePlatQuantiteControl.value != 0) { // effectif déjà présent => on peut modifier l'effectif
      return true;
    }

    // vérif si la limitation n'est atteinte
    const limiteComposantes = this.simplePrestaFormGroup.nombreLimiteFamillePlats;
    if (!limiteComposantes) {
      return true;
    } // Pas de limitation

    // on compte le nombre de composantes soumises à restriction ayant de l'effectif
    const nombreComposantes = this.simplePrestaFormGroup
      .famillePlatsControls
      .filter(f => f.isComposanteRestreinte && f.famillePlatQuantiteControl.value != 0)
      .length;
    // on vérifie si on est strictement en dessous du seuil de limitation
    return nombreComposantes < limiteComposantes;
  }

  private applyRule3(fpg: FamillePlatFormGroup, toSubstract: number) {
    let toSubstractFp = toSubstract;
    const length = fpg.portionsControls.length;
    for (let i = length - 1; i >= 0; i--) {
      const portionControl = fpg.portionsControls[i];

      // ignore isDisabled
      if (portionControl.isDisabled) {
        continue;
      }

      toSubstractFp = FormControlHelper.substractValue(portionControl.effectifPortionQuantiteControl, toSubstractFp);
      if (this.prestation.autoriserSaisieTauxPrise) {
        this.calculatePortionPercentage(portionControl);
      }
      if (toSubstractFp == 0) {
        break;
      }
    }
  }

  applyZeroPortions(fpControl: FamillePlatFormGroup) {
    fpControl.portionsControls.forEach(portionControl => {
      if (!portionControl.isDisabled) {
        this.resetPortionToZero(portionControl);
      }
    });

    this.computeFamillePlatControl(fpControl);
  }

  private applyTauxPriseBrassage() {
    this.simplePrestaFormGroup.famillePlatsControls.forEach(fpg => {
      // Sera rempli par la première itération, et utilisé par la deuxième s'il y en a une
      fpg.portionsControls.forEach((portion, i) => {
        // On recalcule l'effectif avec le % actuel du premier
        this.calculateEffectifByPercentage(portion);

        // On réajuste le % avec le nouvel effectif
        this.calculatePortionPercentage(portion);
      });

      this.adjustGap(fpg);
      this.computeFamillePlatControl(fpg);
    });
  }

  // Ajuste le total de la famille de plat pour correspondre au total d'effectif de la prestation
  // en ajoutant les effectifs à partir du début (rule2) ou en retirant les effectifs en commençant par la fin (rule3)
  private adjustGap(fpg: FamillePlatFormGroup) {
    this.applyRule2And3OnAllFamillePlats(fpg);
  }

  /** Get data */
  public getData(): PrestationFormModel {
    // HACK Suite du quick hack : https://github.com/angular/angular/issues/12963
    // 1. on enable les champs pour que le getRawValue renvoie les données
    this.simplePrestaFormGroup.famillePlatsControls.forEach(fp => {
      fp.portionsControls.forEach(pc => {
        if (pc.enableToAvoidGetRawValueIgnore) {
          pc.effectifPortionQuantiteControl.enable();
        }
      });
    });

    const model =  this.prestaForm.getRawValue() as PrestationFormModel;

    // 2. on redisable les champs
    this.simplePrestaFormGroup.famillePlatsControls.forEach(fp => {
      fp.portionsControls.forEach(pc => {
        if (pc.enableToAvoidGetRawValueIgnore) {
          pc.effectifPortionQuantiteControl.disable();
        }
      });
    });

    model.effectifId = this.prestation.effectifId;
    model.prestationName = this.prestation.prestation;
    model.modeSaisie = this.prestation.modeSaisie;

    if (this.hasRegime) {
      model.regimeId = this.prestation.regimeData.regimeId;
      model.regimeEffectifId = this.prestation.regimeData.effectifId;
    }

    // Quick fix : on renseigne l'effectif de la prestation en mode portion à chaque fois pour renvoyer les portions avec (à voir comment refacto cela FRONT + API)
    if (!this.isModeStandard) {
      model.effectifQuantiteControl = this.effectifValue;
    }

    return model;
  }

  resetPortionToZero(portion: PortionFormGroup) {
    portion.effectifPortionPourcentageControl.setValue(0);
    portion.effectifPortionQuantiteControl.setValue(0);
  }

  /** Mode saisie % */
  // On réajuste le % avec le nouvel effectif
  calculatePortionPercentage(portion: PortionFormGroup, effectifValue: number = null): number {
    if (effectifValue == null) {
      effectifValue = this.effectifValue;
    }

    let perc = effectifValue == 0 ? 0 : portion.effectifPortionQuantiteControl.value * 100 / effectifValue;
    perc = Math.round(perc);
    portion.effectifPortionPourcentageControl.setValue(perc);
    portion.lastPourcentage = perc;
    return perc;
  }

  calculateEffectifByPercentage(portion: PortionFormGroup) {
    let val = MathHelper.roundToLowerWhenMiddle(this.effectifValue * portion.effectifPortionPourcentageControl.value / 100);
    val = Math.min(val, this.inputMaxValue);
    portion.effectifPortionQuantiteControl.setValue(val);
  }

  getFamillePlatFormGroup(famillePlatName: string): FamillePlatFormGroup {
    return this.simplePrestaFormGroup.famillePlatsControls.find(fpc => {
      return fpc.famillePlatName == famillePlatName;
    });
  }

  /** BUILD SIMPLE FORM */
  private buildSimplePrestaFormModel(): PrestaFormGroup {
    const model =  this.prestaForm as PrestaFormGroup;
    model.effectifQuantiteControl =  this.prestaForm.get('effectifQuantiteControl') as FormControl;
    const disabledEffectif = !this.prestation.autoriserSaisie;
    if (disabledEffectif) {
      model.effectifQuantiteControl.disable();
    }

    model.effectifQuantitePreco = this.prestation.quantitePrecommande;
    model.effectifId = this.prestation.effectifId;
    model.nombreLimiteFamillePlats = this.prestation.nombreLimiteFamillePlats;

    if (this.hasRegime) {
      model.effectifSansPorcQuantiteControl =  this.prestaForm.get('effectifSansPorcQuantiteControl') as FormControl;
      model.effectifSansPorcQuantitePreco = this.prestation.regimeData.quantitePrecommande;

      if (disabledEffectif) {
        model.effectifSansPorcQuantiteControl.disable();
      }
    }

    model.famillePlatsControls = ( this.prestaForm.get('famillePlatsControls') as FormArray).controls
      .map((fp: FamillePlatFormGroup) => {
        fp.famillePlatName = fp.get('famillePlatName').value;
        fp.famillePlatQuantiteControl =  fp.get('famillePlatQuantiteControl') as FormControl;
        fp.quantiteInitialeFamille = fp.get('quantiteInitialeFamille').value;
        fp.ordre =  fp.get('ordre').value as number;
        fp.isComposanteRestreinte =  fp.get('isComposanteRestreinte').value as boolean;
        fp.autoriserEffectifZero =  fp.get('autoriserEffectifZero').value as boolean;
        fp.portionsControls = ( fp.controls['portionsControls'] as FormArray).controls
          .map((portion: PortionFormGroup) => {
            portion.platName = portion.get('platName').value;
            portion.famillePlatName = fp.famillePlatName;
            portion.tooltip = portion.get('tooltip').value;
            // can't check on enums in view
            const modeSaisie: number = portion.get('modeSaisie').value;
            portion.effectifPortionId = portion.get('effectifPortionId').value;
            portion.isDisabled = modeSaisie == ModeSaisiePortionEnum.LectureSeule;
            portion.effectifPortionQuantiteControl =  portion.get('effectifPortionQuantiteControl') as FormControl;
            portion.effectifPortionQuantitePreco = portion.get('effectifPortionQuantitePreco').value;
            portion.effectifPortionPourcentageControl =  portion.get('effectifPortionPourcentageControl') as FormControl;

            // Calc %
            portion.lastPourcentage = this.calculatePortionPercentage(portion, model.effectifQuantiteControl.value || 0);
            const baseDisable = !this.prestation.autoriserSaisie || portion.isDisabled;
            const disablePortion = baseDisable || (modeSaisie == ModeSaisiePortionEnum.TauxPrise);
            const disablePortionPercentage = baseDisable || (modeSaisie == ModeSaisiePortionEnum.Quantite);

            if (disablePortion) {
              portion.effectifPortionQuantiteControl.disable();
              // HACK car bug ng2 : Si on affiche les % et que c'est en taux de prise (et non saisie à la portion)
              // --> on prépare un booléen pour pouvoir enable le champs sur le getRawValue() fait dans le getData()
              //     car les champs disabled et nested  ne sont pas retournés : prestaForm.famillesControls.forEach(f=>f.portionsControls))
              // Issue github, ouvert au 09/02 : https://github.com/angular/angular/issues/12963
              if (this.prestation.autoriserSaisie && this.prestation.autoriserSaisieTauxPrise && modeSaisie == ModeSaisiePortionEnum.TauxPrise) {
                portion.enableToAvoidGetRawValueIgnore = true;
              }
            }

            if (disablePortionPercentage) {
              portion.effectifPortionPourcentageControl.disable();
            }

            return portion;
          });
        return fp;
      });

    return model;
  }
}

interface PrestaFormGroup extends FormGroup {
  effectifId: number;
  regimeId: number;
  effectifQuantiteControl: FormControl;
  effectifQuantitePreco: number;
  effectifSansPorcQuantiteControl: FormControl;
  effectifSansPorcQuantitePreco: number;
  nombreLimiteFamillePlats?: number;
  famillePlatsControls: FamillePlatFormGroup[];
}

interface FamillePlatFormGroup extends FormGroup {
  famillePlatName: string;
  ordre: number;
  autoriserEffectifZero: boolean;
  isComposanteRestreinte: boolean;
  quantiteInitialeFamille: number;
  famillePlatQuantiteControl: FormControl;
  portionsControls: PortionFormGroup[];
}

class PortionFormGroup extends FormGroup {
  isModeSaisieTauxDePrise: boolean;
  isModeSaisieQuantite: boolean;
  isDisabled: boolean;
  effectifPortionId: number;
  platName: string;
  famillePlatName: string;
  tooltip: string;
  effectifPortionQuantiteControl: FormControl;
  effectifPortionQuantitePreco: number;
  effectifPortionPourcentageControl: FormControl;
  lastPourcentage: number;
  enableToAvoidGetRawValueIgnore: boolean;
}
