<div class="main-container">
    <div class="ribbon" [style.background-image]="'url(' + logoContratUrl + ')'">
    </div>
    <div class="page-container">
        <h1>{{typeListType}}</h1>


        <div class="panier-products-container" #stickyContainer>

            <div class="left-side" [class.mobile-cart-visible]="cartIsVisible">
                <div class="panier-container" [stickyBlock]="{'container': stickyContainer, 'scrollContainer': '#antiscroll-body'}">
                    <span class="title" [restaurantColor]="{'type': restaurantType, 'color':true}">Votre Panier</span>
                    <div class="content-container">
                        <div class="date-container">
                            <div>
                                <label class="elior-label">Date {{ isInventaire ? "d'inventaire" : "de commande"}} :</label>
                                <span>{{dateCommande | dateFormat:'DD/MM/YYYY'}}</span>
                            </div>
                            <div *ngIf="!isInventaire">
                                <label class="elior-label" for="date-previsionnelle">Date de livraison prévisionnelle :</label>
                                <app-date-input #desktopDate *ngIf="dateLivraisonCalendarData && !isInventaire && !consult" id="desktop-date" [(ngModel)]="dateLivraison"
                                    [calendarInfo]="dateLivraisonCalendarData"></app-date-input>
                                <span *ngIf="consult">{{dateLivraison | dateFormat:'DD/MM/YYYY'}}</span>
                            </div>
                        </div>
                        <app-cart [consult]="consult" [isInventaire]="isInventaire">
                        </app-cart>
                        <div *ngIf="!consult">
                            <button class="generic-button red-button validate-button" (click)="validate()">{{validationButtonLabel}}</button>
                            <button class="generic-button prev-button hidecart-button" (click)="hideCart()">Retour</button>
                        </div>
                        <div class="error-box" *ngIf="commande && commande.validationRequise && !consult && !isAdmin">
                            Cette commande est soumise à validation avant d'être transmise
                        </div>
                    </div>
                </div>
            </div>

            <div class="products-container right-side" [class.mobile-product-container]="cartIsVisible">
                <span class="title" [restaurantColor]="{'type': restaurantType, 'color':true}">Les Produits</span>
                <app-date-input #mobileDate *ngIf="dateLivraisonCalendarData" id="mobile-date" [(ngModel)]="dateLivraison" [calendarInfo]="dateLivraisonCalendarData"
                    class="mobile-date" [restaurantColor]="{'type': restaurantType, 'isSecondary': true, 'background':true}"></app-date-input>
                <div class="picturesMessage">Les photos présentées sur le site sont non contractuelles</div>
                <hr class="separator" />
                <div class="product-list" [restaurantColor]="{'type': restaurantType, 'border':true}">
                    <div class="search-header" [restaurantColor]="{'type': restaurantType, 'background':true}">
                        <div [restaurantColor]="{'type': restaurantType,'isBlack':false, 'background':true}" class="search-panel" [class.hidden]="!searchIsVisible">
                            <div>
                                <img src="/assets/images/epicerie-inventaire/loupe-white.png" />
                            </div>
                            <input #searchInput type="text" class="search-input" [(ngModel)]="searchText" (ngModelChange)="filterArticlesEmitter.next()"
                                placeholder="Recherche par mots clés" />
                            <div>
                                <img src="/assets/images/close.png" (click)="showSearch(false)" class="pointer" />
                            </div>
                        </div>
                        <div class="left">
                            <div [restaurantColor]="{'type': restaurantType,'isBlack':true, 'background':false}" class="pointer" (click)="showSearch(true)">
                                <img src="/assets/images/epicerie-inventaire/loupe-white.png" />
                            </div>
                            <div [restaurantColor]="{'type': restaurantType,'isBlack':true, 'background':true}">TOUS</div>
                        </div>
                        <div class="liste">{{typeListName}}</div>
                    </div>
                    <app-article [isInventaire]="isInventaire" [type]="restaurantType" *ngFor="let article of filteredArticles" [article]="article"
                        [consult]="consult">
                    </app-article>
                </div>
            </div>

        </div>

    </div>
</div>
