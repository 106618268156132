import { DocumentModel, DocumentationTypeEnum } from './models/documentheque.model';
import { DocumenthequeApiResponse } from './models/api/documentheque.apiresponse';

export class DocumenthequeProjector {

    public static projectDocuments(documentsResponse: DocumenthequeApiResponse.Documentation[]): DocumentModel[] {
        return documentsResponse.map(documentResp => {
            let document: DocumentModel = {
                nom: documentResp.nom,
                fileName: documentResp.fileName != null ? documentResp.fileName : documentResp.nom + documentResp.extension,
                description: documentResp.description,
                documentationId: documentResp.documentationId,
                isDownloading: false,
                type : this.projectDocumentType(documentResp.type)
            };

            return document;
        });
    }

     private static projectDocumentType(docType: string): DocumentationTypeEnum {
        return DocumentationTypeEnum[docType];
    }

}